.blogcard {
  margin-top: 68px;
}

.blogcard_title {
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 36px;
  margin: 0;
}

.blogcard_description {
  margin-top: 20px;
  font-size: 16px;
  line-height: 18px;
  padding: 4px;
}

.blogcard_coordinates {
  margin-top: 16px;
}

.blogcard_select_element_desc {
  color: #999999;
  line-height: 16px;
}

.blogcard_input {
  height: 30px;
  padding-left: 20px;
  font-size: 16px;
  border-radius: 8px;
  outline: 0;
  border: 2px solid #c0c0c0;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
}

.blogcard_select_container {

}

.blogcard_select_list {
  width: 100%;
  border: 2px solid #c0c0c04d;
  border-radius: 8px;
  margin-top: 12px;
  box-sizing: border-box;
  max-height: 200px;
  min-height: 200px;
  overflow: auto;
  padding: 10px 0;
}

.blogcard_select_element {
  padding: 6px 8px;
  box-sizing: border-box;
  cursor: pointer;
}

.blogcard_select_element:hover {
  background: #eeecec;
}