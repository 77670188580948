.infoblock {
  margin-top: 68px;
  width: 100%;
  padding: 32px 136px 32px 40px;
  border-radius: 10px;
  background-color: #f5f5f7;
  box-sizing: border-box;
}

.infoblock_title {
  font-size: 18px;
  line-height: 26px;
}

.error {
  border: 2px solid tomato !important;
}
