.locale_switch {
  display: flex;
  background: #1a245780;
  border-radius: 30px;
  width: min-content;
  margin: 2px 10px;
}

.locale_switch_element {
  border-radius: 30px;
  padding: 6px 16px;
  box-sizing: border-box;
  transition: 0.3s;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}

.ce-block__content h1 {
  font-size: 38px;
}

.ce-block__content h2 {
  font-size: 36px;
}

.ce-block__content h3 {
  font-size: 18px;
}

.locale_switch_element.active {
  background: rgba(26, 36, 87, 1);
}

.locale_switch_element + .locale_switch_element {
  margin-left: -8px;
}

.should_translate {
  background: #ebebff;
  border-radius: 8px;
  padding: 4px;
}

.ce-block__content {
  max-width: 100%;
}

.ce-toolbar__content {
  max-width: 100%;
}

.cdx-list {
  margin-top: 6px;
  padding: 0;
}

.cdx-list .cdx-list__item {
  position: relative;
  list-style: none;
  width: 100%;
  padding: 7px 0 5px 24px;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0;
  margin-top: 0;
}

.cdx-list__item {
  display: flex;
  align-items: center;
}

.cdx-list .cdx-list__item:before {
  position: absolute;
  content: '';
  height: 11px;
  width: 11px;
  border: 2px solid var(--mantine-color-green-5);
  left: 0;
  top: 16px;
  border-radius: 50%;
  background-color: transparent;
  box-sizing: border-box;
}

.ce-paragraph {
  font-size: 18px;
  line-height: 26px;
}

.ce-popover__container {
  min-width: fit-content;
}

*[contenteditable='true']:focus-visible {
  outline: -webkit-focus-ring-color outset 2px;
  border-radius: 2px;
}
