.slider {
  margin-left: 48px;
  position: relative;
  height: 5px;
  width: 130px;
  background-color: #f5f5f7;
}

.sliderLine {
  border-radius: 2.5px;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #5bb884;
  width: calc(13px * var(--rating));
}
