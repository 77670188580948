.title_and_description {
  margin-top: 68px;
}

.title_and_description_title {
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 36px;
  margin: 0;
  white-space: break-spaces;
}

.title_and_description_description {
  margin-top: 20px;
  font-size: 16px;
  line-height: 1.6em;
  padding: 4px;
  white-space: break-spaces;
}

.set_onlymobile {
  padding: 6px;
  box-sizing: border-box;
}

.set_onlymobile div {
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.set_onlymobile input {
  margin-left: 6px;
  width: 14px;
  height: 14px;
}

.error {
  border: 2px solid tomato !important;
}
