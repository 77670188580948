.popover_item {
  cursor: pointer;
  padding: 4px 12px;
}

.popover_item:hover {
  background: var(--mantine-color-gray-6);
  color: white;
}

.popover_item_disabled {
  pointer-events: none;
  opacity: 0.6;
}
