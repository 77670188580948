.description {
  margin-top: 68px;
}

.description_preview {
  margin-top: 20px;
  font-size: 16px;
  line-height: 1.6em;
  padding: 4px;
  white-space: break-spaces;
}

.description_title {
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 36px;
  margin: 0;
  white-space: break-spaces;
}

.description_about {
  margin-top: 20px;
  font-size: 16px;
  line-height: 1.6em;
  padding: 4px;
  white-space: break-spaces;
}

.error {
  border: 2px solid tomato !important;
}
