.content {
    background: #EAE9E4;
    display: flex;
    margin: 20px auto 0;
    justify-content: center;
    gap: 20px;
    position: relative;
    width: 100%;
}

.editorContainer {
    position: relative;
    min-width: 49%;
    max-width: 49%;
}

.editorContainerFull {
    min-width: 80%;
    max-width: 80%;
}

.iconContainer {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1;
}

.languageIcon{
  width: 16px!important;
  height: 16px!important;
  display: inline-block;
}
