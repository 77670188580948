.content {
  background: #eae9e4;
  display: flex;
  margin: 20px auto 0;
  justify-content: center;
  width: 100%;
}

.content.hideEnContent {
  justify-content: space-between;
}

.editorContainer {
  position: relative;
  min-width: 80%;
  max-width: 80%;
}

.editorContainer.hideEnContent {
  min-width: 47%;
  max-width: 47%;
}

.main {
  flex-grow: 1;
}

.icons {
  position: sticky;
  top: 50%;
  height: min-content;
  margin: 0 6px;
  display: flex;
  flex-direction: column;
}

.iconContainer {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
}
