.root {
  width: inherit;
  padding: 0 30px 30px;
  box-sizing: border-box;
  min-width: 1000px;
  overflow: auto;
  height: 100vh;
}

.bottomBar {
  padding-bottom: 90px;
}
