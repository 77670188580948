.description_gpt {
    margin-top: 68px;
}

.description_container_gpt {
    display: flex;
    gap: 20px;
}

.buttons_container_gpt{
    display: flex;
    gap: 20px;
}

.description_title_gpt {
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 36px;
    margin: 0;
    white-space: break-spaces;
}

.description_about_gpt {
    width: 100%;
    margin-top: 20px;
    font-size: 16px;
    line-height: 1.6em;
    padding: 4px;
    white-space: break-spaces;
    border: 1px solid #323232;
    min-height: 300px;
}

.generate_button_gpt, .update_button_gpt {
    max-width: 300px;
    margin-top: 20px;
    text-align: center;
    white-space: nowrap;
    background: var(--mantine-color-green-5);
    border-radius: 4px;
    padding: 13px;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    transition: all .2s ease-in-out;
    outline: none;
    border: none;
}

.update_button_gpt:disabled {
    background: #ccc;
    user-select: none;
    cursor: not-allowed;
    position: relative;
}

.update_button_gpt:hover:disabled {
    background: #ccc;
    user-select: none;
    cursor: not-allowed;
    position: relative;
}

.generate_button_gpt:disabled {
    user-select: none;
    cursor: not-allowed;
    position: relative;
    background-image: linear-gradient(to right, #1bc47d 0%, #0E8388 50%, #1bc47d 100%);
    background-size: 400% 400%;
    animation: gradient-bg 2.3s ease-in-out infinite;
}

.update_button_gpt:hover, .generate_button_gpt:hover {
    background: rgba(27,196,125, .8);
}

.generate_button_gpt:hover:disabled {
    background-image: linear-gradient(to right, #1bc47d 0%, #0E8388 50%, #1bc47d 100%);
    background-size: 400% 400%;
    animation: gradient-bg 2.3s ease-in-out infinite;
}

.error {
    border: 2px solid tomato !important;
}

.description_about_gpt:disabled {
    user-select: none;
    cursor: not-allowed;
}

.svg_container_gpt {
    display: flex;
    justify-content: center;
    align-items: center;
}

.svg_loader_gpt {
    border: 6px solid #f3f3f3;
    border-top: 6px solid rgba(27,196,125, .8);
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes gradient-bg {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}
