.list {
  margin-top: 68px;
}

.list_title {
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 36px;
  margin: 0;
}

.list_input_wrapper {
  display: flex;
  align-items: center;
  padding: 3px;
  box-sizing: border-box;
}

.list_checkbox {
  margin-left: 8px;
}

.list_label {
  font-size: 14px;
  cursor: pointer;
  user-select: none;
}

.list_element {
  padding-left: 30px;
  position: relative;
  padding-top: 12px;
  width: 100%;
  padding-bottom: 13px;
}

.list_element_text {
  font-size: 20px;
  max-width: 90%;
  list-style: none;
  letter-spacing: 0;
  line-height: 28px;
}

.list_element:before {
  position: absolute;
  content: "";
  box-sizing: border-box;
  background: var(--mantine-color-green-5);
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEwIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDQuNzA0MDJMNC40MTg1MiA4TDkgMSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjwvcGF0aD4KPC9zdmc+);
  height: 16px;
  background-repeat: no-repeat;
  background-position: 50%;
  width: 16px;
  left: 0;
  color: #fff;
  top: 18px;
  border-radius: 50%;
}

.list_list {
  width: 100%;
  padding-left: 0;
  margin-top: 20px;
  column-count: 1;
  display: inline-block;
}

.list_description {
  margin-top: 32px;
  font-size: 20px;
  line-height: 34px;
}

.list_element_remove {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  background: rgba(0,0,0,0.4);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list_element_remove:before, .list_element_remove:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 2px;
  background: rgba(255,255,255,0.5);
  transition: 0.2s;
}

.list_element_remove:hover:after, .list_element_remove:hover:before {
  background: white;
}

.list_element_remove:before {
  transform: rotate(45deg);
}

.list_element_remove:after {
  transform: rotate(-45deg);
}

.list_add {
  padding: 13px;
  border-radius: 3px;
  border: 1px solid rgba(201,201,204,.48);
  font-size: 14.9px;
  background: #fff;
  box-shadow: 0 2px 2px 0 rgb(18 30 57 / 4%);
  color: #707684;
  text-align: center;
  cursor: pointer;
  margin-top: 12px;
}

.list_add:hover {
  background: #fbfcfe;
  box-shadow: 0 1px 3px 0 rgb(18 30 57 / 8%);
}