.root {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    width: inherit;
    min-height: 80px;
    justify-content: space-between;
    max-height: 80px;
    position: sticky;
    margin-bottom: 20px;
    top: 0;
    padding: 0 30px;
    z-index: 20;
    box-sizing: border-box;
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.3);
}

.backgroundLight {
    background: var(--mantine-color-gray-4);
}

.backgroundDark {
    background: var(--mantine-color-white);
}