.root {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: inherit;
    min-height: 90px;
    max-height: 90px;
    bottom: 0;
    overflow: hidden;
    background: blue;
    position: fixed;
}