.root {
  --sb-main-gray: #eae9e4;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  inline-size: 100%;
  block-size: 100vh;
  background-color: var(--sb-main-gray);
  overflow: auto;
}
