.procedures {
  margin-top: 68px;
}

.procedures_title {
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 36px;
  margin: 0;
}

.procedures_list_content {
  margin-top: 12px;
}

.procedures_element {
  position: relative;
  background: #f5f5f7;
  padding: 21px 40px 17px 24px;
  box-sizing: border-box;
  border-radius: 4px;
}

.procedures_element + .procedures_element {
  margin-top: 20px;
}

.procedures_element_text {
  font-size: 16px;
  max-width: 90%;
  list-style: none;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: 12px;
}

.procedures li + li {
  margin-top: 32px;
}

.procedures_element_title {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
  width: 100%;
  cursor: pointer;
  font-weight: 600;
}

.procedures_list {
  width: 100%;
  padding-left: 0;
  margin-top: 20px;
  column-count: 1;
  display: inline-block;
}

.procedures_top_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.procedures_template {
  margin-left: 12px;
  height: 36px;
  padding-left: 18px;
  border-radius: 4px;
  font-weight: 600;
  padding-right: 18px;
  font-size: 14px;
  user-select: none;
  cursor: pointer;
  border: 1px solid transparent;
  color: #fff;
  background: var(--mantine-color-green-5);
  display: flex;
  align-items: center;
}

.procedures_template:hover {
  background: var(--mantine-color-green-8);
}

.procedures_list_icon svg {
  width: 12px;
  height: 12px;
  stroke: 4px;
}

.procedures_list_icon {
  position: absolute;
  top: 10px;
  color: rgba(255,255,255,0.5);
  font-size: 10px;
  right: 40px;
  width: 20px;
  height: 20px;
  background: rgba(0,0,0,0.4);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.procedures_block_input {
  margin-bottom: 20px;
}

.procedures_block_input:empty:before {
  content: attr(data-before);
}

.procedures_element_remove {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  background: rgba(0,0,0,0.4);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.procedures_element_remove:before, .procedures_element_remove:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 2px;
  background: rgba(255,255,255,0.5);
  transition: 0.2s;
}

.procedures_element_remove:hover:after, .procedures_element_remove:hover:before {
  background: white;
}

.procedures_element_remove:before {
  transform: rotate(45deg);
}

.procedures_element_remove:after {
  transform: rotate(-45deg);
}

.procedures_add {
  padding: 13px;
  margin-top: 12px;
  border-radius: 3px;
  border: 1px solid rgba(201,201,204,.48);
  font-size: 14.9px;
  background: #fff;
  box-shadow: 0 2px 2px 0 rgb(18 30 57 / 4%);
  color: #707684;
  text-align: center;
  cursor: pointer;
}

.procedures_add:hover {
  background: #fbfcfe;
  box-shadow: 0 1px 3px 0 rgb(18 30 57 / 8%);
}

.procedures_element_duration {
  width: fit-content;
  height: 21px;
  background: #71a6dd;
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  padding: 0 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.procedures_description {
  font-size: 18px;
  line-height: 24px;
  margin-top: 18px;
}

.procedures_includes {
  
}

.procedures_includes_title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  margin-top: 22px;
}

.procedures_includes_text {
  font-size: 16px;
  padding: 6px 0;
  margin: 6px 0;
  box-sizing: border-box;
  max-width: calc(100% - 70px);
}

.procedures_includes_element {
  position: relative;
}

.procedures_input_buttons {
  display: flex;
  gap: 20px;
}

.procedures_input_button_full {

}

.procedures_input_button_part {

}