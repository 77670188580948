.treatments {
  margin-top: 68px;
}

.treatments_title {
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 36px;
  margin: 0;
}

.treatments_img {
  width: 150px;
  height: 150px;
  background: #c0c0c0;
  border-radius: 8px;
  cursor: pointer;
}

.treatments_element {
  position: relative;
  background: #f5f5f7;
  padding: 21px 40px 17px 24px;
  box-sizing: border-box;
  border-radius: 4px;
}

.treatments_element + .treatments_element {
  margin-top: 20px;
}

.treatments_element_text {
  font-size: 16px;
  max-width: 90%;
  list-style: none;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: 12px;
}

.treatments_element_title {
  font-size: 18px;
  letter-spacing: 0;
  margin-top: 12px;
  line-height: 26px;
  width: 100%;
  cursor: pointer;
  font-weight: 600;
}

.treatments_list {
  width: 100%;
  padding-left: 0;
  margin-top: 20px;
  column-count: 1;
  display: inline-block;
}

.treatments_element_remove {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  background: rgba(0,0,0,0.4);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.treatments_element_remove:before, .treatments_element_remove:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 2px;
  background: rgba(255,255,255,0.5);
  transition: 0.2s;
}

.treatments_element_remove:hover:after, .treatments_element_remove:hover:before {
  background: white;
}

.treatments_element_remove:before {
  transform: rotate(45deg);
}

.treatments_element_remove:after {
  transform: rotate(-45deg);
}

.treatments_add {
  padding: 13px;
  margin-top: 12px;
  border-radius: 3px;
  border: 1px solid rgba(201,201,204,.48);
  font-size: 14.9px;
  background: #fff;
  box-shadow: 0 2px 2px 0 rgb(18 30 57 / 4%);
  color: #707684;
  text-align: center;
  cursor: pointer;
}

.treatments_add:hover {
  background: #fbfcfe;
  box-shadow: 0 1px 3px 0 rgb(18 30 57 / 8%);
}

.treatments_top {
  display: flex;
}

.treatments_right {
  margin-left: 12px;
}

.treatments_template_hint {
  font-size: 20px;
  font-weight: 600;
}

.treatments_templates {
  display: flex;
  gap: 10px;
  margin-top: 8px;
  flex-wrap: wrap;
}

.treatments_template {
  width: 60px;
  cursor: pointer;
}

.treatments_template:hover .treatments_template_img {
  outline: 1px solid var(--mantine-color-green-5);
}

.treatments_template_img {
  width: 100%;
}

.treatments_template_text {
  font-size: 7px;
  margin-top: 4px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.error {
  border: 2px solid tomato !important;
}