.refresh {
  width: 34px;
  height: 34px;
  background: #47a946;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.status {
  min-width: 135px;
  height: 34px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  padding: 0 4px;
  box-sizing: border-box;
}

.table_row {
  cursor: pointer;
}

.table_row:hover {
  background: var(--mantine-color-gray-2);
}

.statusCompleted {
  background: #d3f4e0;
  color: #639375;
}

.statusFailed {
  background: #e74c3c;
  color: #fff;
}

.statusInProgress {
  background: #c7cfd9;
  color: #ffffff;
}
