.image-tool {
  --bg-color: #cdd1e0;
  --front-color: #388ae5;
  --border-color: #e8e8eb;
  margin-top: 24px;
 /** * Tunes * ---------------- */
}
.image-tool__image {
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 10px;
}

.image-tool__image-picture {
  display: flex;
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-height: fit-content;
}

.image-tool__image-picture:before {  
  content: " ";
  display: block;

  position: absolute;
  top: -10px;
  left: 0;
  height: calc(100% + 10px);
  width: 100%;
  background-color: rgb(230, 230, 230);
  border: 2px dotted rgb(200, 200, 200);
  border-radius: 5px;
}

.image-tool__image-picture:after {  
  content: "\f127" " Для замены картинки нажмите на эту область";
  display: block;
  font-size: 16px;
  font-style: normal;
  font-family: FontAwesome;
  color: rgb(100, 100, 100);

  position: absolute;
  top: 5px;
  left: 0;
  width: 100%;
  text-align: center;
}

.image-tool__image-preloader {
  width: 50px;
  height: 50px;
  display: none;
  border-radius: 50%;
  background-size: cover;
  margin: auto;
  position: relative;
  background-color: var(--bg-color);
  background-position: center center;
}
.image-tool__image-preloader::after {
  content: "";
  position: absolute;
  z-index: 3;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid var(--bg-color);
  border-top-color: var(--front-color);
  left: 50%;
  top: 50%;
  margin-top: -30px;
  margin-left: -30px;
  animation: image-preloader-spin 2s infinite linear;
  box-sizing: border-box;
}

.image-tool__caption {
  width: 100%;
  margin-bottom: 4px;
}

.image-tool__caption[contentEditable="true"][data-placeholder]::before {
  position: absolute !important;
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  display: none;
}
.image-tool__caption[contentEditable="true"][data-placeholder]:empty::before {
  display: block;
}
.image-tool__caption[contentEditable="true"][data-placeholder]:empty:focus::before {
  display: none;
}
.image-tool--empty__image {
  display: none;
}
.image-tool--empty__caption, .image-tool--loading__caption {
  display: none;
}
.image-tool--filled .cdx-button {
  display: none;
}
.image-tool--filled__image-preloader {
  display: none;
}
.image-tool--loading__image {
  min-height: 200px;
  display: flex;
  border: 1px solid var(--border-color);
  background-color: #fff;
}
.image-tool--loading__image-picture {
  display: none;
}
.image-tool--loading .cdx-button {
  display: none;
}
.image-tool--withBorder__image {
  border: 1px solid var(--border-color);
}
.image-tool--withBackground__image {
  padding: 15px;
  background: var(--bg-color);
}
.image-tool--withBackground__image-picture {
  max-width: 60%;
  margin: 0 auto;
}
.image-tool--stretched__image-picture {
  width: 100%;
}
@keyframes image-preloader-spin {
  0% {
    transform: rotate(0deg);
 }
  100% {
    transform: rotate(360deg);
 }
}