.root {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  text-decoration: none;
  box-sizing: border-box;
}

.root:hover {
  background-color: var(--mantine-color-gray-6);
}

.selected {
  background-color: var(--mantine-color-gray-6);
}
