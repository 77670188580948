.root {
  display: flex;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  background: #f2f2f2;
}

.sideLeft {
  flex-grow: 0;
  flex-shrink: 0;
}

.main {
  flex-grow: 1;
}

.topbar {
  flex-grow: 0;
  flex-shrink: 0;
}

.content {
  flex-grow: 1;
}
