.errorLabel {
  font-size: 12px;
}

.error > div > input {
  color: #0C181E;
  background-color: #FFE3E6;

  &::placeholder {
    color: #0C181E;
  }
}

.hidden {
  display: none;
}
